import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import ChatModeSelection from '../pages/ChatModeSelection';
import Login from '../pages/auth/Login';
import PersonaSelection from '../pages/PersonaSelection';
import ChatScreen from '../pages/ChatScreen';
const Navigation = () => {

    return (
        <>
            <Router
                basename='/'
            >
                <MainContent />
            </Router>
        </>
    );
};

const MainContent = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname !== '/' && <Header />}
            <main className='align-content-center'>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/chat-mode" element={<ChatModeSelection />} />
                    <Route path="/persona-selection" element={<PersonaSelection />} />
                    <Route path="/chat-screen" element={<ChatScreen />} />
                </Routes>
            </main>
            <Footer />
        </>
    )
}
export default Navigation;