import { FC, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from "react-router-dom";
import Persona from "../components/Persona";
import CustomError from "../utils/CustomError";

const PersonaSelection: FC = () => {
    const navigate = useNavigate();
    const personaList = [
        {
            id: "1",
            personaId: "john",
            name: "John",
            about: "Hi! I'm John, everyone's favorite product consultant",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona1.png'
        },
        {
            id: "2",
            personaId: "sarah",
            name: "Sarah",
            about: "I'm Sarah, a passionate software engineer who loves coding.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona2.png'
        },
        {
            id: "3",
            personaId: "mike",
            name: "Mike",
            about: "Hello, I'm Mike! I work as a data scientist, analyzing big data for insights.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona3.png'
        },
        {
            id: "4",
            personaId: "emma",
            name: "Emma",
            about: "I'm Emma, a UX/UI designer focused on creating user-friendly experiences.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona4.png'
        },
        {
            id: "5",
            personaId: "david",
            name: "David",
            about: "Hey, I'm David. I'm a digital marketing strategist helping businesses grow online.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona5.png'
        },
        {
            id: "6",
            personaId: "linda",
            name: "Linda",
            about: "Hi, I'm Linda. I'm a full-stack developer working with cutting-edge technologies.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona1.png'
        },
        {
            id: "7",
            personaId: "alex",
            name: "Alex",
            about: "I'm Alex! A project manager who ensures that everything runs smoothly.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona2.png'
        },
        {
            id: "8",
            personaId: "olivia",
            name: "Olivia",
            about: "Hey, I'm Olivia! I focus on product management, ensuring the best solutions for our users.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona3.png'
        },
        {
            id: "9",
            personaId: "ethan",
            name: "Ethan",
            about: "I'm Ethan, a passionate AI enthusiast working on machine learning algorithms.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona4.png'
        },
        {
            id: "10",
            personaId: "chloe",
            name: "Chloe",
            about: "Hi! I'm Chloe, a frontend developer who loves building interactive web applications.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona5.png'
        },
    ];

    const [selectedPersona, setSelectedPersona] = useState<any>("");

    const [activeIndex, setActiveIndex] = useState<number>();

    const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

    const handleItemClick = (index: number) => {
        // setActiveIndex(index);
        itemRefs.current.forEach((item) => {
            if (item) {
                item.classList.remove('center');
            }
        });
        const clickedItem = itemRefs.current[index];
        if (clickedItem) {
            clickedItem.classList.add('center');
        }
    };
    return (
        <>
            <section className="persona-section">
                <Container>
                    <Row className="justify-content-center">
                        <Col xxl={11} xl={10} md={12} sm={11} xs={12}>
                            <OwlCarousel
                                className='owl-theme'
                                loop={false}
                                margin={30}
                                nav
                                mouseDrag={false}
                                items={5}
                                smartSpeed={800}
                                navText={["<img src=" + `${process.env.PUBLIC_URL}` + "'/assets/img/left-arrow.svg' alt='left-arrow' />", "<img src=" + `${process.env.PUBLIC_URL}` + "'/assets/img/right-arrow.svg' alt='right-arrow' />"]}
                                responsive={{
                                    0: {
                                        margin: 30,
                                        items: 1,
                                        center: false,
                                        dots: false,
                                        stagePadding: 60
                                    },
                                    600: {
                                        items: 2,
                                        margin: 80,
                                        center: false,
                                        dots: false,
                                    },
                                    992: {
                                        items: 3,
                                        margin: 50,
                                        dots: false,
                                    },
                                    1400: {
                                        items: 5,
                                        dots: false,
                                    },
                                }}
                            >
                                {personaList.map((item: any, index: number) => (
                                    <div
                                        key={index}
                                        className="item"
                                    >
                                        <div
                                            ref={(el) => (itemRefs.current[index + 1] = el)} // Set the ref for each item
                                        >

                                            <div
                                                className={`person-list`}
                                                onClick={() =>
                                                    handleItemClick(item?.id)
                                                }
                                            >
                                                <Persona
                                                    about={item?.about}
                                                    imgSrc={item?.imgSrc}
                                                    name={item?.name}
                                                />
                                            </div>


                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>

                            <div className="text-center mt-lg-5 mt-2">
                                <Button variant="" onClick={() => navigate('/chat-screen')} className="start-btn">Start</Button>
                            </div>

                            <CustomError class="mt-lg-5 mt-4" message="Select a persona amongst the list and then click on Start Chat to start the conversation!" />
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}
export default PersonaSelection;