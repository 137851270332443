import { FC, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row, Stack } from "react-bootstrap";
import Logo from "../../components/Logo";
import { useNavigate } from "react-router-dom";
import { eyeOff, eyeOn, submitIcon } from "../../utils/Icons";
import CustomError from "../../utils/CustomError";

const Login: FC = () => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    return (
        <>
            <section className="login-page h-100">
                <Container className="h-100">
                    <Row className="h-100 align-items-center justify-content-center">
                        <Col xxl={4} lg={5} md={8} className="text-center">
                            <Logo />
                            <Stack className="mt-5">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Please enter your password"
                                        aria-label="Please enter your password"
                                        aria-describedby="basic-addon2"
                                        type={showPassword !== true ? "password" : "text"}
                                    />
                                    <Button
                                        onClick={() => setShowPassword(prevState => !prevState)}
                                        type="button"
                                        variant="eye-icon"
                                    >
                                        {showPassword ? eyeOn() : eyeOff()}
                                    </Button>
                                    <Button type="submit" variant="" className="submit-btn" onClick={() => navigate("/chat-mode")}>
                                        {submitIcon()}
                                    </Button>
                                </InputGroup>
                            </Stack>
                            <CustomError class="mt-3" message="The Password you entered is incorrect." />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default Login;