import { FC, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";

interface EndConversationProps {
    show: boolean;
    onClose: () => void;
    setEndConversationSummary: (value: boolean) => void;
}

const EndConversation: FC<EndConversationProps> = (props) => {
    return (
        <>
            <Modal className="end-conversation-modal" size="lg" show={props.show} onHide={props.onClose} keyboard={false} backdrop="static" centered>
                <Modal.Header className="justify-content-center">
                    <Modal.Title>
                        <h5 className="mt-0">End Conversation</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center text-center">
                        <Col xxl={11}>
                            <p>Are you sure you want to end the  conversation?
                            </p>
                            <p> This action cannot be undone and a summary of the conversation
                                will be created.</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-center mt-3">
                    <Button variant="primary" className="me-2" onClick={() => {
                        console.log("Setting endConversationSummary to true");
                        props.setEndConversationSummary(true);
                        props.onClose();
                    }}>
                        Confirm
                    </Button>
                    <Button variant="secondary" onClick={props.onClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default EndConversation;