import { FC } from "react";

const Loader: FC = () => {
    return (
        <>
            <div className="main-loader">
                <div className="loader" id="loader-4">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </>
    )
};
export default Loader;