
import { FC } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";


const CaseHistorySummary: FC = () => {
    return (
        <>
            <div className="main-history text-center">
                <div className="case-summary">
                    <Row className="justify-content-center" id="no-print">
                        <Col xs="auto" className="text-start">
                            <h6>Download Summary PDF</h6>
                            <div onClick={() => window.print()}>
                                <div className="pdf-grp d-flex align-item-center">
                                    <img width={56} src={process.env.PUBLIC_URL + '/assets/img/pdf.png'} alt="Logo" />
                                    <div className="ms-1">
                                        <h5 className="mb-1">Conversation Completed!</h5>
                                        <p className="m-0">Response as sent by Chatbot</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="case-des mt-3">
                        <h4>VIRTUAL AUDIOLOGY CLINIC: CLIENT DETAILS FOR JANE</h4>
                        <h6>Hearing</h6>
                        <ul>
                            <li>Jane reports experiencing hearing difficulties, particularly in understanding conversations when there is background noise.</li>
                            <li>She often finds herself asking others to repeat themselves during meetings and social interactions.
                            </li>
                            <li>There has been no prior assessment or use of hearing aids.</li>
                        </ul>
                        <h6>Tinnitus</h6>
                        <ul>
                            <li>No complaints or reports of tinnitus.</li>
                        </ul>
                        <h6>Vertigo</h6>
                        <ul>
                            <li> Jane does not experience vertigo or dizziness.</li>
                        </ul>
                        <h6>Noise Exposure</h6>
                        <ul>
                            <li>Jane works in an office environment with minimal exposure to loud noises.</li>
                            <li>Occasionally attends social events where music volume may be high but does not report significant issues related to noise exposure.</li>
                        </ul>
                        <h6>Family History</h6>
                        <ul>
                            <li>
                                No known family history of hearing loss or related conditions.
                            </li>
                        </ul>
                        <h6>Medical History</h6>
                        <ul>
                            <li>Generally healthy with no chronic illnesses that could impact hearing.</li>
                            <li>No previous ear infections or surgeries reported.</li>
                        </ul>
                        <h6> Social/Emotional Impact</h6>
                        <ul>
                            <li>Jane feels her hearing challenges contribute to misunderstandings at work, affecting team dynamics and communication.
                            </li>
                            <li>She expresses frustration over the need for repetition during conversations, which impacts her confidence in professional settings.</li>
                        </ul>
                        <h6>Other Relevant Information</h6>
                        <ul>
                            <li>Jane is proactive about seeking solutions and improving her situation, including exploring options for better communication practices at work.</li>
                        </ul>
                    </div>
                </div >
            </div >
        </>
    )
}
export default CaseHistorySummary;