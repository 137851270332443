import { FC, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from "react-router-dom";

interface HowToUseModalProps {
    setShow: boolean;
    onClose: () => void;
}

const HowToUseModal: FC<HowToUseModalProps> = (props) => {
    const [show, setShow] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const location = useLocation();
    useEffect(() => {
        setCurrentStep(1);
        console.log(props.setShow, "props.setShow");
        const howModal = localStorage.getItem("howToUseModal");
        if (location.pathname === "/") {
            props.onClose();
        }
        else {
            if (!howModal) {
                setShow(true);
                setCurrentStep(1);
                localStorage.setItem('howToUseModal', '');
            }
            else {
                setCurrentStep(1);
            }
        }
    }, [location]);

    const nextStep = () => {
        if (currentStep < 3) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const closeModal = () => {
        setShow(false);
        props.onClose();
        localStorage.setItem('howToUseModal', 'true');
    };

    return (
        <>
            <Modal size="lg" show={props.setShow || show} onHide={closeModal} keyboard={false} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>
                        {currentStep === 1 && (
                            "Welcome to the Virtual Audiology Lab!"
                        )}
                        {currentStep === 2 && (
                            "How to Use"
                        )}
                        {currentStep === 3 && (
                            "Tips and Feedback"
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentStep === 1 && (
                        <div>
                            <p className="mt-1">Enhance your clinical skills through AI-powered patient interactions.
                            </p>
                            <p>Choose your session:</p>
                            <h5>Initial Appointment</h5>
                            <ol>
                                <li>Take case history</li>
                                <li>Conduct hearing tests (using separate software)</li>
                                <li>Explain results and provide recommendations</li>
                            </ol>
                            <h5>Free chat</h5>
                            <ul>
                                <li>Explore various topics</li>
                                <li>Enhance interpersonal skills</li>
                            </ul>
                        </div>
                    )}
                    {currentStep === 2 && (
                        <div>
                            <h5>Initial Appointment:
                            </h5>
                            <ol>
                                <li>Choose an AI patient</li>
                                <li>Gather the patient's history (use microphone to speak, or type)</li>
                                <li>Press "Proceed to Hearing Test" (use separate audiometry software)</li>
                                <li>Return to explain results and give recommendations</li>
                                <li>Press "End Conversation" and reflect</li>
                            </ol>
                            <h5>Free Chat:</h5>
                            <ul>
                                <li>Practice open communication on various topics related to hearing health.</li>
                                <li>Enhance interpersonal skills and explore patient personalities.</li>
                            </ul>
                            <p><span>Remember:</span> Treat each AI interaction as you would a real patient.</p>
                        </div>
                    )}
                    {currentStep === 3 && (
                        <div>
                            <h5>Tips for Success:
                            </h5>
                            <ul>
                                <li>Experiment with different communication styles</li>
                                <li>Adapt your approach to each patient's needs</li>
                                <li>Reflect on what works best in various scenarios</li>
                            </ul>
                            <h5>Your Feedback Matters </h5>
                            <p>Use the 'Feedback' tab to suggest improvements, report issues, or share your experience.</p>
                            <p>Enjoy your virtual clinical experience!</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {/* {currentStep > 1 && (
                        <Button variant="secondary" className="me-2" onClick={prevStep}>
                            Previous
                        </Button>
                    )} */}
                    {currentStep < 3 ? (
                        <Button variant="primary" onClick={nextStep}>
                            Continue
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={() => {
                            closeModal();
                            setCurrentStep(1);
                        }}>
                            Close
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default HowToUseModal;