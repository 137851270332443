
import { FC } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";


const EndConversationSummary: FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="end-summary text-center">
                <div className="case-summary">
                    <div className="case-des mt-3">
                        <h4>Your Summary</h4>
                        <p className="text-dark">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                        <Button variant="secondary" onClick={() => window.print()}>Download Summary PDF</Button>
                    </div>
                </div>
            </div>
            <div className="text-center mt-2">
                <Button className="ms-1" variant="primary" onClick={() => navigate('/chat-mode')}>Back to Home</Button>
            </div>
        </>
    )
}
export default EndConversationSummary;