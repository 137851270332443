import { FC } from "react";
import { Link } from "react-router-dom";

const Logo: FC = () => {
    return (
        <>
            <div className="logo">
                <Link to={"/chat-mode"}>
                    <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="Logo" />
                </Link>
            </div>
        </>
    )
};
export default Logo;