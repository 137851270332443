import { FC } from "react";
import { infoIcon } from "./Icons";
import { Col, Row } from "react-bootstrap";

interface customMessage {
    message: string;
    class: string;
}

const CustomError: FC<customMessage> = (props) => {
    return (
        <>
            <Row className="justify-content-center">
                <Col xs="auto" className={props.class}>
                    <div className="custom-message error text-start">
                        {infoIcon()} <span>{props.message}</span>
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default CustomError;