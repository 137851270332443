import { FC, } from "react";
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

interface HearingTest {
    show: boolean;
    onClose: () => void;
}

const HearingTest: FC<HearingTest> = (props) => {
    return (
        <>
            <Modal className="hearing-test-modal" size="xl" show={props.show} onHide={props.onClose} keyboard={false} backdrop="static" centered>
                <div className="step-box step1" step-active="true">
                    <div className="circle-main">
                        <div className="step-circle">
                            <span>2</span>
                        </div>
                    </div>
                </div>
                <Row className="justify-content-center g-0">
                    <Col xxl={11}>
                        <Modal.Header>
                            <Modal.Title>
                                Proceed to Hearing Test
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="my-2">You will now perform the hearing assessment for this client.
                            </p>
                            <ul>
                                <li>Use the separate hearing testing simulation tool.  </li>
                                <li>Do not close this browser tab.</li>
                                <li>Return to this screen after completing the assessment. </li>
                                <li>Prepare to explain the findings clearly to the patient and make appropiate recommendations. </li>
                            </ul>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" className="text-white" label=" Show patient's test results" />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-end mt-3 g-1 text-center">
                            <Col md="auto" xs="auto">
                                <Button
                                    variant="secondary"
                                >
                                    Explain Test Results
                                </Button>

                            </Col>
                            <Col md="auto" xs="auto">
                                <Button variant="primary" className="" onClick={props.onClose}>
                                    Continue Case History
                                </Button>
                            </Col>
                        </Modal.Footer>
                    </Col>
                </Row>
            </Modal >
        </>
    )
}
export default HearingTest;