import { FC } from "react";
import { Image } from "react-bootstrap";

interface Persona {
    imgSrc: string,
    about: string,
    name: string,
}

const Persona: FC<Persona> = (props) => {

    return (
        <>
            <div className="main-person">
                <div className="person-img">
                    <Image src={props?.imgSrc} alt="" />
                    <div className="hover-content">
                        <h6 className="mt-0">{props?.name}</h6>
                        <p>{props?.about}</p>
                    </div>
                </div>
                <h6>{props?.name}</h6>
            </div>
        </>
    )
}
export default Persona;