import { FC, useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Row } from "react-bootstrap";
import { micIcon, pauseIcon, restartIcon } from "../../../utils/Icons";
import Loader from "../../Loader";

interface SpeechRecognitionText {
    show: boolean;
    onClose: () => void;
    setTextValue: (value: string) => void;
}

const SpeechRecognitionText: FC<SpeechRecognitionText> = (props) => {

    const { transcript, browserSupportsSpeechRecognition, resetTranscript } = useSpeechRecognition();
    const [text, setText] = useState<string>('');
    useEffect(() => {
        props.setTextValue("");

        if (props.show) {
            setText(transcript);
            startListening();
        }
    }, [transcript, props.show]);

    const startListening = () => {
        SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
    };
    const stopListening = () => {
        SpeechRecognition.stopListening();
        props.setTextValue(transcript);
        resetTranscript();
        props.onClose();
    };

    if (!browserSupportsSpeechRecognition) {
        return <div>Your browser does not support speech recognition.</div>;
    }
    return (
        <>
            <Modal className="speech-modal" size="lg" show={props.show} onHide={props.onClose} centered>
                <Modal.Body>
                    <Row className="justify-content-between align-items-center px-4 speech-height">
                        <Col md={{ order: 1, span: "auto" }} xs={{ order: 2, span: 12 }}>
                            <h4>

                                Listening
                            </h4>
                        </Col>
                        <Col md={{ order: 2, span: "auto" }} xs={{ order: 1, span: 12 }}>
                            <div className="rounds-btn">
                                <Button className="pause-btn" onClick={stopListening}>
                                    {pauseIcon()}
                                </Button>
                            </div>
                        </Col>

                        <Col xs={{ order: 3, span: 12 }} className="text-center">
                            <div className="loader">
                                <p className="text-white mt-3">{text}</p>
                            </div>
                            {text === "" ?
                                <>
                                    <Loader />
                                </>
                                : ""
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal >
        </>
    )
}
export default SpeechRecognitionText;