import { FC } from "react";
import { Container } from "react-bootstrap";

const Footer: FC = () => {
    return (
        <>
            <footer>
                <Container>
                    <p className="m-0 text-center">© 2024 National Acoustic Laboratories. All rights reserved.</p>
                </Container>
            </footer>
        </>
    )
}
export default Footer;