import { FC, useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { closeIcon } from "../../../utils/Icons";

interface HearingTestImage {
    show: boolean;
    onClose: () => void;
}

const HearingTestImage: FC<HearingTestImage> = (props) => {
    return (
        <>
            <Modal className="hearing-test-image-modal" size="lg" show={props.show} onHide={props.onClose} keyboard={false} backdrop="static" centered>
                <Modal.Body className="text-cetner">
                    <Row className="justify-content-center">
                        <Col xs="auto">
                            <div className="hearing-img-main position-relative">
                                <Button variant="" className="close-btn" onClick={props.onClose}>
                                    {closeIcon()}
                                </Button>
                                <Image src={process.env.PUBLIC_URL + '/assets/img/hearing-test.png'} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal >
        </>
    )
}
export default HearingTestImage;