import React from 'react';
import Navigation from "./routes/Navigation";

function App() {
  return (
    <>
      <Navigation />
    </>
  );
}

export default App;
