import { FC } from "react";
import { Button, Col, Row } from "react-bootstrap";

interface Suggestion {
    setTextValue: (value: string) => void;
}

const Suggestion: FC<Suggestion> = (props) => {

    return (
        <>
            <div className="suggestion-box" id="no-print">
                <Row className="g-2 mb-2">
                    <Col xs="auto">
                        <Button onClick={() => props.setTextValue('Hi, How are you?')} variant="secondary">
                            Hi, How are you?
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button onClick={() => props.setTextValue('Hello, what brings you in today?')} variant="secondary">
                            Hello, what brings you in today?
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default Suggestion;