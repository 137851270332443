import { FC, useEffect, useState } from "react";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";
import CaseHistorySummary from "./CaseHistorySummary";
import Suggestion from "./Suggestion";
import EndConversationSummary from "./EndConversationSummary";

interface ChatBoxProps {
    speechTexts: string;
    endSummary: boolean;
    onEndConversation: (value: boolean) => void;
    openHearingTestModal: (value: boolean) => void;
    openSpeechModal: (value: boolean) => void;
}

const ChatBox: FC<ChatBoxProps> = (props) => {
    const CURSOR_CLASS_NAME = 'custom-type-animation-cursor';
    const [checkAnimation, setCheckAnimation] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [caseHistory, setCaseHistory] = useState(false);
    const [endConversationSummary, setEndConversationSummary] = useState(props.endSummary);
    const [textValue, setTextValue] = useState(props.speechTexts || "");
    useEffect(() => {
        console.log(props.endSummary, "endSummary")
        if (checkAnimation) {
            setTimeout(() => {
                setShowMessage(true);
            }, 1000)
        }
        console.log(props.speechTexts);
    }, [checkAnimation]);

    useEffect(() => {
        if (props.speechTexts) {
            setTextValue(props.speechTexts);
        }
        if (props.endSummary) {
            setEndConversationSummary(props.endSummary);
        }
        console.log(props.endSummary, "endSummary")
    }, [props.speechTexts, props.endSummary]);
    return (
        <>
            <div className="chat-box h-100">
                <Row className="h-100 flex-column">
                    <Col className="top-part" xs={12}>
                        <ul className="msg-area">
                            <li className="chat-msg message-user">
                                <div>Hello, what brings you in today?
                                </div>
                            </li>
                            <li className="chat-msg message-bot">
                                <div>
                                    Hey, Lokesh! I'm just here and ready to help with anything you need. What's on your mind today?
                                </div>
                            </li>

                            {checkAnimation &&
                                <li className="chat-msg message-user">
                                    <div>I need some help</div>
                                </li>
                            }
                            {showMessage && (
                                <li className="chat-msg message-bot">
                                    <TypeAnimation
                                        sequence={[
                                            "Of course! What do you need help with?",
                                            (el: any) => el.classList.remove(CURSOR_CLASS_NAME),
                                        ]}
                                        className={CURSOR_CLASS_NAME}
                                        wrapper="div"
                                        cursor={false}
                                    />

                                </li>
                            )}
                            {caseHistory &&
                                <li className="chat-msg message-user">
                                    <div>
                                        create case history
                                    </div>
                                </li>}
                        </ul>
                        {caseHistory &&
                            <CaseHistorySummary />
                        }
                        {endConversationSummary &&
                            <EndConversationSummary />
                        }
                    </Col>

                    {/* 
                         <Loader /> 
                    */}
                    {!endConversationSummary && (
                        <>
                            <Suggestion setTextValue={(value: any) => setTextValue(value)} />
                            <Col className="bottom-part" xs={12} id="no-print">
                                <Row className="g-2">
                                    <Col>
                                        <div className="chat-textarea">
                                            <input value={textValue} onChange={(e) => setTextValue(e.target.value || "")} placeholder="Enter Your Input"></input>
                                        </div>
                                    </Col>
                                    <Col xs="auto">
                                        <Button className="chat-btn" onClick={() => props.openSpeechModal(true)}>
                                            <svg id="mic" xmlns="http://www.w3.org/2000/svg" width="36" height="28" viewBox="0 0 40.5 40.5">
                                                <path id="Path_17" data-name="Path 17" d="M20.391,27.844a6.33,6.33,0,0,0,6.328-6.328V6.328a6.328,6.328,0,0,0-12.656,0V21.516A6.33,6.33,0,0,0,20.391,27.844ZM29.25,17.719v3.8a8.859,8.859,0,0,1-17.719,0v-3.8H9v3.8A11.4,11.4,0,0,0,19.125,32.839v5.13H14.063V40.5H26.719V37.969H21.656v-5.13A11.4,11.4,0,0,0,31.781,21.516v-3.8Z" transform="translate(-1.406)" fill="#f1582c" />
                                                <path id="Path_18" data-name="Path 18" d="M0,0H40.5V40.5H0Z" fill="none" />
                                            </svg>
                                        </Button>
                                    </Col>
                                    <Col xs="auto">
                                        <Button className="chat-btn" onClick={() => setCheckAnimation(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32.004" height="24" viewBox="0 0 32.004 32.004">
                                                <g id="send" transform="translate(0.004)">
                                                    <path id="Path_14" data-name="Path 14" d="M37.661,2.27a.944.944,0,0,1-.213.307L17.28,22.744l5.7,9.494a1.062,1.062,0,0,0,1.893-.127Z" transform="translate(-5.761 -0.757)" fill="#001e3e" />
                                                    <path id="Path_15" data-name="Path 15" d="M10.013,20.527l-9.494-5.7a1.062,1.062,0,0,1,.127-1.893L30.493.14a1.113,1.113,0,0,0-.313.22Z" transform="translate(0 -0.047)" fill="#001e3e" />
                                                    <path id="Path_16" data-name="Path 16" d="M0,0H32V32H0Z" transform="translate(-0.001)" fill="none" />
                                                </g>
                                            </svg>
                                        </Button>
                                    </Col>
                                </Row>
                                <Stack className="chat-btns mt-2 pb-2" direction="horizontal" gap={1}>
                                    <Button
                                        variant="primary"
                                        onClick={() => props.openHearingTestModal(true)}
                                        className="ms-auto"
                                    >
                                        Proceed to Hearing Test
                                    </Button>
                                    <Button
                                        onClick={() => setCaseHistory(true)}
                                        variant="primary">
                                        Case History Summary
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        className="end-con"
                                        onClick={() => props.onEndConversation(true)}
                                    >
                                        End Conversation
                                    </Button>
                                </Stack>
                            </Col>
                        </>
                    )}
                </Row >
            </div>
        </>
    )
}
export default ChatBox;